import React from "react"
import Section from "./global/Section"

export default function footer() {
  return (
    <Section bgColor={"black"} container="container-fluid">
      <div className="row align-items-end">
        <div className="col-sm-12">
          <p className="l-grey ">
            ©{new Date().getFullYear()} Tai's Portofolio
          </p>
        </div>
      </div>
    </Section>
  )
}
