import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { Component } from "react"
import logo from "../images/Tai.svg"
import "../style/navbar.scss"
import "../style/main.scss"

export default class Header extends Component {
  state = {
    navbarOpen: false,
    navToggle: "nav-bg",
    navLinkToggle: "links links-collapse",
    menuToggle: "",
  }

  navbarHandler = () => {
    this.state.navbarOpen
      ? this.setState({
          navbarOpen: false,
          navToggle: "nav-bg",
          navLinkToggle: "links links-collapse",
          menuToggle: "",
        })
      : this.setState({
          navbarOpen: true,
          navToggle: "nav-bg-active",
          navLinkToggle: "links links-collapse-active",
          menuToggle: "change",
        })
  }

  render() {
    return (
      <div className="nav-menu">
        <div className="container">
          <nav className="nav-container">
            <div className="brand">
              <Link to="/">
                <img src={logo} height="44px" className="my-lg-3 my-md-3" />
              </Link>
            </div>

            <div className="toggler">
              <button onClick={this.navbarHandler}>
                <span className={this.state.menuToggle}>
                  <div className="bar1"></div>
                  <div className="bar2"></div>
                  <div className="bar3"></div>
                </span>
              </button>
            </div>

            <div className={this.state.navLinkToggle}>
              <ul className="link-container align-items-center">
                <li>
                  <a
                    href="doc/resume.pdf"
                    target="_blank"
                    rel="noopener noreferral"
                  >
                    Resume
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/in/taihung-lu-150193106/"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    LinkedIn
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
        <div className={this.state.navToggle} />
      </div>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}
