import React from "react"

export default function Section({
  children,
  secHeight,
  bgColor,
  extraMargin,
  secStyle,
  container,
}) {
  return (
    <div
      className={secStyle}
      style={{ background: `${bgColor}`, marginTop: `${extraMargin}` }}
    >
      <div className={secHeight}>
        <div className={container}>{children}</div>
      </div>
    </div>
  )
}
Section.defaultProps = {
  secHeight:"sec-lg",
  bgColor: { background: "transparent" },
  container: "container",
}
